<template>
  <div class="forgot-password-send-email">
    <b-container>
      <b-row class="pt-4">
        <b-col cols="12" md="6">
          <h3 class="title">{{$t('Send email')}}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" :md="!checkEmail ? '4' : '9'">
          <ValidationObserver ref="validator" v-if="!checkEmail">
            <form @submit.prevent="sendEmail">
              <RFValidation name="Email" rules="required|email" >
                <RFInput label="Email" v-model="email"/>
              </RFValidation>

              <RFButton class="mt-4" :label="$t('Send email')" v-if="!loading"/>
              <RFLoader class="mt-4" :size="30" color="#24425B" v-else />
            </form>
          </ValidationObserver>
          <div class="check-email" v-else>
                {{$t('Forgot password confirmation message',{email : $t('email'),emailAddress : this.email,password : $t('password')})}}
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'

import {httpStatusCodes} from '@/enums'
import {userService} from '@/services'

export default {
  name: 'ForgotPasswordSendEmail',
  components: {
    RFInput,
    RFButton,
    RFValidation,
    RFLoader,
  },
  data() {
    return {
      email: null,
      loading: false,
      checkEmail: false
    }
  },
  methods: {
    async sendEmail() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        const response = await userService.sendEmail(this.email);
        if (response && response.status === httpStatusCodes.SUCCESS) {
          this.checkEmail = true;
        } else { //status != 200 => 404 in case of email not found
          this.$toasted.error(this.$i18n.t(`Email not found message`,{email: this.email}))
        }
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.forgot-password-send-email {
  height: calc(100vh - 86px);
  padding-top: $menu-height;
  min-height: 80vh;

  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
  }
  .email {
    @include font-style($montserrat, 'medium', $font-14);
  }
  .check-email {
    @include font-style($montserrat, 'semibold', $font-16);
    color: $dark-blue;
    margin-top: 30px;
  }
}
</style>
